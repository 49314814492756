@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");


.carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.carousel-image2 {
  margin-top: 10%;
  width: 100%;
  height: auto;
  object-fit: cover;
}
.vdo-pl{
  margin-left: 30%;

}
.carousel-div{

}
.carousel-container {
  max-width: 70%; /* Set a maximum width for the container */
  margin: 4% auto;
  
}
a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* 
 


/* navbar style start  */



.main-nav {
  width: 100%;
  
}

.logo-img{
  width: 10%;
  margin-top: 2%;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-add{
  padding: 18%;
}

/* ----------- Grid part ends ----------------- */

.logo h2 {
  font-size: 2.5rem;
  font-weight: 400;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#eee, rgb(69, 57, 248));
  text-align: center;
  margin-top: 3%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo h2 span {
  font-size: 3.5rem;
}








/* responsive css style  */
@media (max-width: 1080px) {
  

  .logo,
  .menu-link ul,
  .social-media ul {
    height: 8rem;
  }
}

/* responsive css style  */
@media (max-width: 998px) {
  

  

  .logo,
  .social-media ul {
    height: 7rem;
  }

  
}

@media (max-width: 798px) {
 

  .logo,
  .social-media ul {
    height: 6rem;
  }

}

@media (max-width: 520px) {
 
  .logo,
  .social-media ul {
    height: 5rem;
  }

  .logo h2 {
    font-size: 1.4rem;
  }
  .logo-img{
    margin-top: 4%;
    width: 20%;
  }

  



 
}