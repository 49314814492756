.button-add{
    display: block;
    margin-bottom: 5px;
    width: 100%;
    padding: 5px 7px;
    border: 2px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}
.button2{
    cursor: pointer;
    width: 100%;
    display: block;
    background-color: #555;
    border:0;
    color: #fff;
    border-radius: 5px;
    padding: 7px 0;
}